import React from "react";
import { observer } from "mobx-react";
import { PulseLoader } from "react-spinners";

import { Box, Heading, Text, Grid, TextInput, FormField, Image } from "grommet";

import ensureStore from "../store";

import SideBar from "./SideBar";
import Modal from "../components/Modal";
import ListHeader from "../components/ListHeader";
import Button from "../components/Buttons/Button";
import EditButton from "../components/Buttons/EditButton";
import Setting from "../components/Screens/Setting";
import SessionSummary from "../components/Sessions/SessionSummary";

import languageCountryMenuImage from "../assets/settings/language-country-menu.jpg";

class Screen extends React.Component {
  constructor(props) {
    super(props);

    this.handleSettingChanged = this.handleSettingChanged.bind(this);
    this.handleShowRenameModal = this.handleShowRenameModal.bind(this);
    this.handleDismissRenameModal = this.handleDismissRenameModal.bind(this);
    this.handleRenameScreen = this.handleRenameScreen.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);

    this.state = { screenId: null, screenName: "", showRenameModal: false };
    this.store = ensureStore();
  }

  componentDidMount() {
    this.parseUrl(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.parseUrl(nextProps);
  }

  parseUrl(props) {
    const { screenId } = props.match.params;

    this.setState({ screenId });

    this.store.screens.fetchById(screenId);
    this.store.sessions.fetch([screenId], 0, 10);
  }

  handleShowRenameModal() {
    this.setState({ showRenameModal: true });
  }

  handleDismissRenameModal() {
    this.setState({ showRenameModal: false, screenName: "" });
  }

  handleNameChange(e) {
    this.setState({ screenName: e.target.value });
  }

  handleRenameScreen() {
    const screen = this.store.screens.items.get(this.state.screenId);

    if (screen) {
      screen.updateName(this.state.screenName);
    }
  }

  handleSettingChanged(key, value) {
    const screen = this.store.screens.items.get(this.state.screenId);

    if (screen) {
      screen.updateSetting(key, value);
    }
  }

  handleFetchMoreSessions(screen) {
    this.store.sessions.fetch([screen.id], screen.sessions.length, 50);
  }

  renderSessions(screen) {
    if (!screen) {
      return null;
    }

    const items = screen.sessions.map(session => (
      <SessionSummary key={session.id} session={session} />
    ));

    const isLoading = this.store.sessions.isLoading;
    const icon = isLoading ? <PulseLoader size={6} color="#FFF" /> : null;

    return (
      <Box gap="hair" margin={{ top: "large" }}>
        <Box pad={{ vertical: "medium" }}>
          <Heading level={3}>Sessions</Heading>
        </Box>
        <ListHeader
            isLoading={isLoading}
            metricHeaders={[
              {
                label: "Selected products",
                sortKey: "selectedItemCount",
                sortDescending: true
              },
              {
                label: "Basket items",
                sortKey: "basketSkuCount",
                sortDescending: true
              },
              {
                label: "Checkout value",
                sortKey: "totalPrice",
                sortDescending: true
              }
            ]}
          />
        {items}
        {screen.sessions.length ? (
          <Button
            label="Show more"
            icon={icon}
            disabled={isLoading}
            onPress={() => this.handleFetchMoreSessions(screen)}
            alignSelf="center"
            margin={{ top: "medium" }}
            isReverse
            isPrimary
          />
        ) : null}
      </Box>
    );
  }

  renderSettings(screen) {
    if (!screen || !screen.settings) {
      return null;
    }

    return (
      <Box direction="row" gap="small" fill={true} animation="fadeIn">
        <Box fill={true} background="white" pad="medium">
          <Grid
            alignSelf="start"
            columns={{
              count: 2,
              size: "auto",
              alignContent: "center"
            }}
            gap="small"
          >
            <Setting
              label="Store Id"
              setting={screen.settings.get("storeId")}
              onSettingChanged={this.handleSettingChanged}
              description={
                <Text>This is a unique identifier for the store.</Text>
              }
            />

            <Setting
              label="Menu"
              setting={screen.settings.get("menu")}
              onSettingChanged={this.handleSettingChanged}
            />

            <Setting
              label="Language"
              setting={screen.settings.get("defaultLanguage")}
              onSettingChanged={this.handleSettingChanged}
            />

            <Setting
              label="Available Languages"
              setting={screen.settings.get("availableLanguages")}
              onSettingChanged={this.handleSettingChanged}
            />

            <Setting
              label="Country"
              setting={screen.settings.get("defaultDeliveryCountry")}
              onSettingChanged={this.handleSettingChanged}
            />

            <Setting
              label="Available Countries"
              setting={screen.settings.get("availableDeliveryCountries")}
              onSettingChanged={this.handleSettingChanged}
              description={
                <Box gap="medium" fill>
                  <Text>
                    Select all countries that should be available in the menu on
                    the screen.
                  </Text>
                  <Image
                    fit="contain"
                    src={languageCountryMenuImage}
                    alignSelf="stretch"
                  />
                </Box>
              }
            />

            <Text alignSelf="center">Inactivity Timeout</Text>
            <Box direction="row" gap="medium">
              <Setting
                setting={screen.settings.get("inactivityTimeout")}
                onSettingChanged={this.handleSettingChanged}
                unit="sec"
              />
              <Setting
                setting={screen.settings.get("inactivityUITimeout")}
                onSettingChanged={this.handleSettingChanged}
                unit="sec"
              />
            </Box>

            <Setting
              label="Policy URL"
              setting={screen.settings.get("policyUrl")}
              onSettingChanged={this.handleSettingChanged}
            />
          </Grid>
        </Box>
        <Box fill={true} background="white" pad="medium">
          <Grid
            columns={{
              count: 2,
              size: "auto"
            }}
            gap="small"
          >
            <Setting
              label="Checkout Flow"
              setting={screen.settings.get("checkoutFlows")}
              onSettingChanged={this.handleSettingChanged}
            />

            <Setting
              label="Contact Info"
              setting={screen.settings.get("checkoutContactInfoOption")}
              onSettingChanged={this.handleSettingChanged}
            />

            <Setting
              label="Pricelist Id"
              setting={screen.settings.get("priceListId")}
              onSettingChanged={this.handleSettingChanged}
            />

            <Setting
              label="Stock"
              setting={screen.settings.get("stockType")}
              onSettingChanged={this.handleSettingChanged}
            />

            <Setting
              label="Product View Style"
              setting={screen.settings.get("productDetailStyle")}
              onSettingChanged={this.handleSettingChanged}
            />

            <Setting
              label="Product Listing Elements"
              setting={screen.settings.get("productListingElements")}
              onSettingChanged={this.handleSettingChanged}
            />

            <Setting
              label="Show Out of Stock Products"
              setting={screen.settings.get("showOutOfStockProducts")}
              onSettingChanged={this.handleSettingChanged}
            />

            <Setting
              label="Show Currency Codes"
              setting={screen.settings.get("showCurrencyCodes")}
              onSettingChanged={this.handleSettingChanged}
            />

            <Setting
              label="Show Stock Levels"
              setting={screen.settings.get("showStockInfo")}
              onSettingChanged={this.handleSettingChanged}
            />

            <Setting
              label="Product Comparison"
              setting={screen.settings.get("isProductComparisonEnabled")}
              onSettingChanged={this.handleSettingChanged}
            />

            <Setting
              label="Demo Screen"
              setting={screen.settings.get("isDemo")}
              onSettingChanged={this.handleSettingChanged}
            />

          </Grid>
        </Box>
      </Box>
    );
  }

  render() {
    const screen = this.store.screens.items.get(this.state.screenId);

    return (
      <React.Fragment>
        <SideBar history={this.props.history} location={this.props.location} />
        <Box direction="column" background={{ color: "light-2" }} pad="xlarge">
          <Box
            pad={{ top: "medium", bottom: "large" }}
            alignSelf="end"
            align="end"
          >
            <Box direction="row">
              <Heading
                level={1}
                size="large"
                style={{ fontWeight: "400", letterSpacing: "-0.033em" }}
              >
                {screen ? screen.name : null}
              </Heading>
              <EditButton
                margin={{ right: "-55px", top: "-25px" }}
                onPress={this.handleShowRenameModal}
              />
            </Box>
          </Box>
          {this.renderSettings(screen)}
          {this.renderSessions(screen)}
        </Box>
        <Modal
          heading="Rename screen"
          isActive={this.state.showRenameModal}
          onConfirm={this.handleRenameScreen}
          onDismiss={this.handleDismissRenameModal}
          canConfirm={this.state.screenName.length > 0}
          confirmLabel="Rename"
          description={"Please enter the new screen name."}
          title="Rename screen"
        >
          <FormField>
            <TextInput
              type="text"
              value={this.state.screenName}
              onChange={this.handleNameChange}
              placeholder="Name"
              width="xlarge"
            />
          </FormField>
        </Modal>
      </React.Fragment>
    );
  }
}

export default observer(Screen);
